<template>
  <transition :name="currentAnimation">
    <div class="body fixed modal-container" v-if="display" @click.stop="clickTarget">
      <transition :name="currentAnimation">
        <div
          :style="{ background: background }"
          :class="[size === 'large' ? 'large-body' : 'modal-body']"
          v-if="display"
          class="scrollbar"
        >
          <div
            class="flex justify-between items-center px-6 pt-6"
            v-if="!hideClose"
          >
            <p
              class="text-black text-lg w-full md:w-auto text-left font-semibold"
            >
              {{ title }}
            </p>
            <img
              class="h-3 w-3 cursor-pointer"
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1623525601/customer/Union_yx6ulk.svg"
              alt="cancel"
              @click="closeDisplay"
            />
          </div>
          <!-- <AnimateHeight> -->
          <div
            class="scrollbar"
            :class="[padding, size !== 'large' && 'modal-content']"
          >
            <slot />
          </div>
          <div class="info">
            <slot name="info"></slot>
          </div>

          <!-- </AnimateHeight> -->
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
import AnimateHeight from "../AnimateHeight";
export default {
  components: {
    AnimateHeight,
  },
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "",
    },
    padding: {
      type: String,
      default: "p-4 md:p-6",
      required: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
      required: false,
    },
    background: {
      type: String,
      default: "white",
      required: false,
    },
  },
  data: () => ({
    matchWidth: "slideUp",
  }),
  computed: {
    currentAnimation() {
      if (window.matchMedia("(max-width: 1024px)").matches) {
        return "slideUp";
      } else {
        return "fade";
      }
    },
  },
  watch: {
    display(value) {
      if (value) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  methods: {
    closeDisplay() {
      this.$emit("close");
    },
    clickTarget(e) {
      if (e.target.className.includes("modal-container")) {
        this.closeDisplay();
      }
    },
  },
};
</script>
<style scoped>
@import "../../assets/styles/animation.css";
#body {
  font-family: "Aventa", Helvetica;
}
.modal-container {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgb(76, 74, 82, 0.7);
  z-index: 10;
}
.large-body,
.modal-body {
  position: fixed;
  bottom: 10px;
  border-radius: 1rem;
  margin: auto;
  /* border-top-left-radius: 20px;
    border-top-right-radius: 20px; */
  /* max-height: 80vh; */
  /* overflow-y: auto; */
  width: 95%;
  left: 2.5%;
  right: 2.5%;
  box-shadow: 0px -10px 50px rgba(175, 177, 225, 0.15);
  /* z-index: 50; */
}

.info {
  position: absolute;
  bottom: 1rem;
  width: 100%;
}

.large-body {
  /* height: 90vh; */
  height: calc(var(--vh, 1vh) * 90);
  overflow: auto;
  overscroll-behavior: contain;
}

.modal-content {
  /* max-height: 85vh; */
  max-height: calc(var(--vh, 1vh) * 85);
  overscroll-behavior: contain;
  overflow-y: auto;
  /* padding: 2rem; */
}

.modal-content::-webkit-scrollbar {
  width: 5px !important;
}
.modalcontent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(211, 211, 211);
  border-radius: 10px;
}
.modal-content::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 10px;
}

@media (min-width: 1024px) {
  .modal-container {
    display: grid;
    place-items: center;
  }
  .large-body {
    position: relative;
    max-width: 53.125rem;
    height: 52rem;
    max-height: 90vh;
  }
  .modal-body {
    position: initial;
    max-width: 36rem;
    border-radius: 1rem;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s cubic-bezier(0.45, 0.25, 0.6, 0.95);
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translate(0%, -10px);
  opacity: 0;
}
</style>
