var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: _vm.currentAnimation } }, [
    _vm.display
      ? _c(
          "div",
          {
            staticClass: "body fixed modal-container",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.clickTarget.apply(null, arguments)
              },
            },
          },
          [
            _c("transition", { attrs: { name: _vm.currentAnimation } }, [
              _vm.display
                ? _c(
                    "div",
                    {
                      staticClass: "scrollbar",
                      class: [
                        _vm.size === "large" ? "large-body" : "modal-body",
                      ],
                      style: { background: _vm.background },
                    },
                    [
                      !_vm.hideClose
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex justify-between items-center px-6 pt-6",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-black text-lg w-full md:w-auto text-left font-semibold",
                                },
                                [_vm._v(" " + _vm._s(_vm.title) + " ")]
                              ),
                              _c("img", {
                                staticClass: "h-3 w-3 cursor-pointer",
                                attrs: {
                                  src: "https://res.cloudinary.com/zillaafrica/image/upload/v1623525601/customer/Union_yx6ulk.svg",
                                  alt: "cancel",
                                },
                                on: { click: _vm.closeDisplay },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "scrollbar",
                          class: [
                            _vm.padding,
                            _vm.size !== "large" && "modal-content",
                          ],
                        },
                        [_vm._t("default")],
                        2
                      ),
                      _c("div", { staticClass: "info" }, [_vm._t("info")], 2),
                    ]
                  )
                : _vm._e(),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }